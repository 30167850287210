import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import cookies from 'vue-cookies'
import { DateTime } from 'luxon'
import { dbDataHandle } from './function/index'
import axios from 'axios'
// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    timer: '',
    navbar: false,
    weather: [],
    pop: [],
    reserverate: '',
    currentWeather: [],
    nextdayWeather: [],
    solarKwh: ''
  },
  getters: {
    getTimenow (state) {
      // luxon
      return state.timer
    }
  },
  mutations: {
    LOGIN_OUT () {
      cookies.remove('Authorization')
      router.push({
        path: '/',
        name: 'login'
      }).catch(() => {})
    },
    COUNTING_TIMER (state) {
      setInterval(function () {
        state.timer = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss')
      }, 1000)
    },
    LOGIN_SUCCESS () {
      router.push({
        path: '/'
      }).catch(() => {})
    },
    Member () {
      if (cookies.get('Authorization')) {
      } else {
        router.push({
          path: '/login'
        }).catch(() => {})
      }
    },
    HANDLE_CURRENT_WEATHER (state, payload) {
      const temp = dbDataHandle(payload.temp.day / 10, 1)
      const humidity = payload.humidity
      const icon = payload.weather[0].icon
      const weatherIconUrl = `https://openweathermap.org/img/wn/${icon}@2x.png`
      const description = payload.weather[0].description
      state.currentWeather = [temp, humidity, weatherIconUrl, description]
    },
    HANDLE_NEXTDAY_WEATHER (state, payload) {
      const temp = payload.temp.day
      const description = payload.weather[0].description
      const icon = payload.weather[0].icon
      const weatherIconUrl = `https://openweathermap.org/img/wn/${icon}@2x.png`
      state.nextdayWeather = [temp, description, weatherIconUrl]
    },
    HANDEL_POP (state, payload) {
      // state.pop = dbDataHandle(payload * 100, 0)
      state.pop = payload * 100
    },
    HANDLE_RESERVERATE (state, payload) {
      state.reserverate = payload.operatingReserveRate
    },
    GETSOLARKWH (state, payload) {
      state.solarKwh = dbDataHandle(payload.data.data)
    },
    GETCONTRACTRATE (state, payload) {
      state.contractRate = payload.data.data
    }
  },
  actions: {
    async loginFunction ({ commit }, member) {
      const login = await axios.post('/api/auth/login',
        {
          account: member.account,
          password: member.password
        })
      if (login.data.success) {
        commit('LOGIN_SUCCESS')
        Vue.swal('登入成功')
      } else {
        Vue.swal('帳號或密碼錯誤')
      }
    },
    weatherAPI ({ commit }) {
      axios.get('https://api.openweathermap.org/data/2.5/forecast/daily?lat=25.0478&lon=121.5319&appid=618aaaa4fffcb24d5194539b1fb8acb1&lang=zh_tw').then(({ data }) => {
        commit('HANDEL_POP', data.list[0].pop)
        commit('HANDLE_CURRENT_WEATHER', data.list[0])
        commit('HANDLE_NEXTDAY_WEATHER', data.daily[1])
      })
    },
    operatingReserveRate ({ commit }) {
      const date = DateTime.local().toFormat('yyyy-MM-dd')
      axios.get(`https://tpcems.tier.org.tw/api/tpcems/open/home/operating-reserve-by-date?date=${date}`).then(({ data }) => {
        commit('HANDLE_RESERVERATE', data)
      })
    },
    async getSolarKwToday ({ commit }) {
      const token = cookies.get('Authorization')
      const solarKwToday = await axios.post('/api/cems/allKwhToday',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (solarKwToday.data.success) {
        commit('GETSOLARKWH', solarKwToday)
      }
    },
    async getContractRate ({ commit }) {
      const token = cookies.get('Authorization')
      const contractRate = await axios.post('/api/cems/contractRate',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (contractRate.data.success) {
        commit('GETCONTRACTRATE', contractRate)
      }
    }
  },
  modules: {
  }
})
