<template>
  <div id="app">
    <!-- <div class="loginout" v-if="navbar">
      <h3 @click="loginOut">登出</h3>
    </div> -->
    <!-- <div class="containter"> -->
    <!-- <div class="title" v-if="navbar">
      <h2 style="padding-left:5px">士林區區域電能管理系統</h2>
      <h2 class="timeRwd">{{getTimenow}}</h2>
    </div> -->
    <!-- <div id="nav" v-if="navbar"> -->
      <!-- <div class="a"><router-link to="/energyMap" ><button class="navbutton">能源地圖</button></router-link></div>
      <div class="a"><router-link to="/information" ><button class="navbutton">發用電資訊</button></router-link></div> -->
      <!-- <div class="a"><router-link to="/newIndex" ><button class="navbutton x">index</button></router-link></div> -->
      <!-- <div class="a hoverSlider" @mouseenter="sliderOpen" @mouseleave="sliderClose">
        <router-link to="/response/bidsuggestion" ><button class="navbutton">需量反應</button></router-link>
        <div class="slider" style="width:100%" v-if='slider' @click='sliderClose' >
          <div><router-link to="/response/bidsuggestion" ><button class="navbutton x">投標建議</button></router-link></div>
          <div><router-link to="/response/winningbid" ><button class="navbutton x">得標及抑低分配發布</button></router-link></div>
          <div><router-link to="/response/realtime" ><button class="navbutton x">即時執行情形</button></router-link></div>
          <div><router-link to="/response/history" ><button class="navbutton x">歷史紀錄</button></router-link></div>
          <div><router-link to="/response/member" ><button class="navbutton x">群組成員資料</button></router-link></div>
        </div>
      </div> -->
      <!-- <div class="a"><router-link to="/login" ><button class="navbutton">登入</button></router-link></div> -->
    <!-- </div> -->
     <transition name="pageTransition" mode="out-in">
      <router-view/>
    </transition>
  </div>
  <!-- </div> -->
</template>
<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import cookies from 'vue-cookies'
import router from './router/index'
export default {
  // async created () {
  //   const data = await fetch('/api/test/test', {
  //     method: 'POST'
  //   })
  //   const result = await data.json()
  //   console.log(result)
  // }
  data () {
    return {
      timer: '',
      slider: false,
      transitionName: ''
    }
  },
  methods: {
    ...mapMutations([
      'COUNTING_TIMER',
      'Member'
    ]),
    ...mapActions([
      'weatherAPI'
    ]),
    loginOut () {
      cookies.remove('login')
      console.log(cookies.get('login'))
      router.push({
        path: '/',
        name: 'login'
      }).catch(() => {})
      // this.member()
    },
    sliderOpen () {
      this.slider = true
    },
    sliderClose () {
      this.slider = false
    }
  },
  created () {
    this.Member()
  },
  mounted () {
    this.COUNTING_TIMER()
    this.weatherAPI()
  },
  computed: {
    ...mapGetters([
      'getTimenow'
    ]),
    ...mapState([
      'login',
      'navbar'
    ])
  }
}
</script>
<style lang="scss" scoped>
.pageTransition-enter-active{
  animation: coming .7s;
  opacity: 0;
}
.pageTransition-leave-active{
  animation: going .7s;
}

@keyframes coming{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  @keyframes going{
    from{
    }
    to{
      opacity: 0;
    }
  }
// @import './assets/scss/App.scss'
</style>
