import axios from 'axios'
import cookies from 'vue-cookies'
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  try {
    if (error.response.status && error.response.status === 401) {
      // sessionStorage.setItem('login', false)
      cookies.remove('Authorization')
      window.location.reload()
    }
  } catch (err) {
    // console.log('No response error in axios interceptor')
  }
})
export default axios
