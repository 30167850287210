import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueHighcharts from 'vue-highcharts'
import VueDatepickerLocal from 'vue-datepicker-local'
import VueGoodTablePlugin from 'vue-good-table'
import VueCookies from 'vue-cookies'
import 'vue-good-table/dist/vue-good-table.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import interceptors from './http'
import VueSweetalert2 from 'vue-sweetalert2'
import VueHtml2Canvas from 'vue-html2canvas'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueHtml2Canvas)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.use(VueHighcharts)
Vue.use(VueDatepickerLocal)
Vue.use(VueGoodTablePlugin)
Vue.use(VueCookies)
Vue.use(interceptors)
Vue.use(VueSweetalert2)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
