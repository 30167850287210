/* eslint-disable */
const dbDataHandle = (num, to = 2) => {
    try {
      if (isNaN(num) || num === -Infinity || num === Infinity || num === null || num === undefined || num < 0) return 0
  
      let strNum = (typeof num === 'string') ? num : num.toString()
      let result = ''
      let i = 0
      let j = ''
  
      // 10.15
      for (i; i < strNum.length; i++) {
        if (strNum[i] !== '.') {
          result += strNum[i]
        } else {
          result += (to === 0) ? '' : strNum[i]
          j = i
        }
  
        if (i === (j + to)) break
      }
  
      return result * 1
    } catch (err) {
      console.log(err)
      return 0
    }
  }
  const countDown = (time, minute = 0, year, month, date) => {
    let plus = 0
      const now = new Date().getTime()
      const endTime = new Date()
      if (year && month && date) {
        endTime.setFullYear(year)
        endTime.setMonth(month * 1 - 1)
        endTime.setDate(date)
      }
      endTime.setHours(time)
      endTime.setMinutes(minute)
      endTime.setSeconds(0)
      const timeCompare = endTime.getTime() - now >= 0 ? 1 : 0
      if (!timeCompare) {
        endTime.setHours(24)
        plus = 11
      }
      const offsetTime = (endTime.getTime() - now) / 1000
      const sec = '0' + parseInt(offsetTime % 60)
      const min = '0' + parseInt((offsetTime / 60) % 60)
      const hr = parseInt(offsetTime / 60 / 60)
      return `${hr + plus}時${min.slice(-2)}分${sec.slice(-2)}秒`
}

export { dbDataHandle, countDown }