import Vue from 'vue'
import VueRouter from 'vue-router'
import cookies from 'vue-cookies'
import axios from 'axios'
// import energyMap from '../views/energyMap.vue'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'newIndex',
    component: () => import('../views/newIndex.vue')
  },
  {
    path: '/electricity',
    name: 'electricity',
    component: () => import('../views/Electrcity.vue')
  },
  {
    path: '/group',
    name: 'group',
    component: () => import('../views/group.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/Download.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/History.vue')
  },
  {
    path: '/bidCalc',
    name: 'bidCalc',
    component: () => import('../views/BidCalc.vue')
  },
  {
    path: '/bidRecord',
    name: 'bidRecord',
    component: () => import('../views/BidRecord.vue')
  },
  {
    path: '/realTime',
    name: 'realTime',
    component: () => import('../views/RealTime.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'login') {
    const token = cookies.get('Authorization')
    const access = await axios.post('/api/auth/authorizeToken', { accessToken: token })
    if (!access.data.success) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
